/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2022-02-11 15:19:56
 * @LastEditors: 张志浩
 * @LastEditTime: 2022-02-15 14:41:41
 */
import request from "@/utils/request.js";

export const getManagerEmails = (params) =>
  request("post", "/gov/getManagerEmails", params); // 修改管理员邮箱

export const updateManagerEmails = (params) =>
  request("post", "/gov/updateManagerEmails", params); // 修改管理员邮箱

export const updateManagerEmailsFlag = (params) =>
  request("post", "/gov/updateManagerEmailsFlag", params); // 修改管理员邮箱

export const getProjectListForZhenJiang = (params) =>
  request("post", "/gov/getProjectListForZhenJiang", params); // 获取项目列表 镇江专供

export const getProjectDetailForZhenJiang = (params) =>
  request("post", "/gov/getProjectDetailForZhenJiang", params); // 获取项目详情 镇江专供

export const updateProjectReadedForZhenJiang = (params) =>
  request("post", "/gov/updateProjectReadedForZhenJiang", params); // 更新项目为已读 镇江专供

export const updateAllProjectReadedForZhenJiang = (params) =>
  request("post", "/gov/updateAllProjectReadedForZhenJiang", params); // 全部更新为已读 镇江专供
