<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张志浩
 * @Date: 2022-02-11 16:00:16
 * @LastEditors: 张志浩
 * @LastEditTime: 2022-02-15 15:18:34
-->
<template>
  <div class="dialogContain">
    <el-dialog
      :visible.sync="mVisible"
      :show-close="false"
      custom-class="dialog emailDialog"
      @before-close="closeModal"
    >
      <div class="emailRow">
        <span>邮箱配置</span>
        <el-input v-model="email"></el-input>
      </div>
      <div class="emailRow">
        <span>启用邮箱</span>
        <el-switch v-model="managerEmailsFlag"></el-switch>
      </div>
      <div class="footer" style="margin-top: 20px">
        <el-button @click="closeModal" size="middle">取消</el-button>
        <el-button @click="confirmModal" size="middle" type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  updateManagerEmails,
  updateManagerEmailsFlag,
  getManagerEmails,
} from "@/apis/zhenjiang";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // managerEmailsArr
      email: "",
      managerEmailsFlag: false,
    };
  },
  computed: {
    mVisible: {
      get() {
        return this.visible;
      },
      set(s) {
        this.$emit("update:visible", s);
      },
    },
  },
  watch: {
    visible(val) {
      console.log(val);
      if (val) {
        this.getEmail();
      }
    },
  },

  methods: {
    //获取邮箱
    getEmail() {
      getManagerEmails().then((res) => {
        this.email =
          (res.result.managerEmailsArr &&
            res.result.managerEmailsArr.length &&
            res.result.managerEmailsArr[0]) ||
          "";
        this.managerEmailsFlag = res.result.managerEmailsFlag ? true : false;
      });
    },
    //关闭dialog
    closeModal() {
      this.email = "";
      this.managerEmailsFlag = false;
      this.$emit("update:visible", false);
    },
    confirmModal() {
      let inputPattern = new RegExp(
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      );
      if (!inputPattern.test(this.email) && this.email) {
        this.$message1("请输入正确的邮箱", "error");
        return;
      }
      let managerEmailsArr = this.email ? [this.email] : null;
      Promise.all([
        updateManagerEmailsFlag({
          managerEmailsFlag: this.managerEmailsFlag ? 1 : 0,
        }),
        updateManagerEmails({ managerEmailsArr }),
      ]).then(() => {
        this.$message1("邮箱配置成功", "success");
        this.closeModal();
      });
    },
  },
};
</script>
<style lang="scss">
.dialogContain .emailDialog {
  width: 500px;
  height: 280px;
}
</style>
<style lang="scss" scoped>
.emailRow {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  span {
    width: 80px;
    font-size: 14px;
    color: #333;
  }
  .el-switch,
  .el-input {
    flex: 1;
  }
}
.el-button {
  margin-top: 0;
  input {
    font-size: 14px;
  }
}
</style>
